import {
  setPageScroll
} from "openseadragon";

// 导航栏显示隐藏
function scroll(fn) {
  var beforeScrollTop = $(this).scrollTop(),
    fn = fn || function() {};
  window.addEventListener("scroll", function() {
    var afterScrollTop = $(this).scrollTop(),
      delta = afterScrollTop - beforeScrollTop;
    if (delta === 0) return false;
    fn(delta > 0 ? "down" : "up");
    beforeScrollTop = afterScrollTop;
  }, false);
}
scroll(function(direction) {
  if(direction == "down"){
    $(".nav_box").css("display","none")
  }else{
    $(".nav_box").css("display","block")
  }
});
$(window).scroll(() => {
  // 铜雕背景显示隐藏
  if ($(window).scrollTop() > 4000) {
    $(".hua_box").css("background", "rgba(0, 0, 0, 0)")
  } else {
    $(".hua_box").css("background", "rgba(0, 0, 0, 1)")
  }

  // 铜雕字显示隐藏
  if ($(window).scrollTop() > 4090) {
    $(".td_zi_1_x,.td_tu").stop().fadeIn()
  } else {
    $(".td_zi_1_x,.td_tu").stop().fadeOut()
  }

  // 铜雕字gif显示隐藏
  if ($(window).scrollTop() > 4333) {
    $(".td_tu").stop().fadeIn()
  } else {
    $(".td_tu").stop().fadeOut()
  }

  // btn_1 hover提示
  if ($(window).scrollTop() > 2333) {
    $(".dj,.btn").css("display", "block")
  } else {
    $(".td_tu").css("display", "none")
  }


  // 山水 js_2显示隐藏
  if ($(window).scrollTop() > 130) {
    $(".js_2").css("display", "none")
  } else {
    $(".js_2").css("display", "block")
  }

  // 山水 js_ss js_1显示隐藏
  if ($(window).scrollTop() > 1550) {
    $(".js_1,.js_ss").css("display", "none")
  } else {
    $(".js_1,.js_ss").css("display", "block")
  }

  // 山水 js_3显示隐藏
  if ($(window).scrollTop() > 3850) {
    $(".js_3").css("display", "none")
  } else {
    $(".js_3").css("display", "block")
  }

  // 山水 js_4显示隐藏
  if ($(window).scrollTop() > 5850) {
    $(".js_4").css("display", "none")
  } else {
    $(".js_4").css("display", "block")
  }

  // 山水 ss_r_js显示隐藏
  if ($(window).scrollTop() > 7500) {
    $(".ss_r_js").css("opacity", "1")
  } else {
    $(".ss_r_js").css("opacity", "0")
  }

  // 山水 ss_r显示隐藏
  if ($(window).scrollTop() > 7000) {
    $(".ss_r").css("opacity", "1")
  } else {
    $(".ss_r").css("opacity", "0")
  }

  // 神佛fo_zi显示隐藏
  if ($(window).scrollTop() > 1100) {
    $(".fo_zi").stop().fadeOut(800)
  } else {
    $(".fo_zi").stop().fadeIn(800)
  }

  // 神佛 fo_1显示隐藏
  if ($(window).scrollTop() >= 1000) {
    $(".fo_1,.fo_t").addClass("blurs")
  } else {
    $(".fo_1,.fo_t").removeClass("blurs")
  }

  // 神佛 两边的字显示隐藏
  if ($(window).scrollTop() >= 445) {
    $(".zi_left_box,.zi_right_box").stop().fadeOut(400);
  } else {
    $(".zi_left_box,.zi_right_box").stop().fadeIn(400)

  }
})

// // 禁止右键
// document.oncontextmenu = function(event) {
//   if (window.event) {
//     event = window.event;
//   }
//   try {
//     var the = event.srcElement;
//     if (!((the.tagName == "INPUT" && the.type.toLowerCase() == "text") || the.tagName == "TEXTAREA")) {
//       return false;
//     }
//     return true;
//   } catch (e) {
//     return false;
//   }
// }
