import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './plugins/mobile.js'
import './plugins/gf.js'
import './assets/css/global.css'
import './assets/fonts/iconfont.css'
import axios from 'axios'
import Cookies from 'js-cookie'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'
import screenfull from "screenfull";

Vue.config.productionTip = false;
// 配置API请求路径
Vue.prototype.$http = axios;
Vue.prototype.$cookie = Cookies;
Vue.prototype.$screenfull = screenfull;

NProgress.configure({
  easing: 'ease', // 动画方式，和css动画属性一样（默认：ease）
  speed: 1000, // 递增进度条的速度，单位ms（默认： 200）
  showSpinner: false, // 是否显示加载ico
  trickle: true, //是否自动递增
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比，0-1（默认：0.08）
  parent: '#app', //指定此选项以更改父容器（默认：body）
})

router.beforeEach((to, from, next) => {
  //每次切换页面时，调用进度条
  NProgress.start();
  // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
  next();
});
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})

//在request拦截器中显示进度条Nprogress.start()
axios.interceptors.request.use(config => {
  //请求开始时显示进度条
  Nprogress.start()
  return config
})
//response中完成进度条Nprogress.done()
axios.interceptors.response.use(config => {
  //服务响应时完成进度条
  Nprogress.done()
  return config
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
