<template>
  <div class="con">
    <div class="search_box">
      <div style="width: 65%;display: inline-block;margin-right: 5%;">
        <el-input v-model="orderNo" placeholder="*请输入订单号"></el-input>
      </div>
      <div style="width: 30%;display: inline-block;">
        <el-button type="primary">物流查询</el-button>
      </div>
    </div>
    <div class="search_box">
      <div class="kd_head">
        <div style="display: inline-block;width: 40%;">
          <div style="font-size: 130%;margin-bottom: 5%;font-weight: bold;">张家界市</div>
          <div style="font-size: 90%;color: darkgray;">苏雪琪</div>
        </div>
        <i class="el-icon-minus" style="font-size: 2.5em;width: 20%;position: relative;bottom: 50%;"></i>
        <div style="display: inline-block;width: 40%;">
          <div style="font-size: 130%;margin-bottom: 5%;font-weight: bold;">鄂尔多斯市</div>
          <div style="font-size: 90%;color: darkgray;">田 磊</div>
        </div>
        <el-divider>
          <el-tag size="mini">运单号:SF5546579875465</el-tag></i>
        </el-divider>
      </div>
      <el-timeline>
        <el-timeline-item placement="top" v-for="(activity, index) in activities" :key="index" :icon="activity.icon"
          :type="activity.type" :color="activity.color" :size="activity.size" :timestamp="activity.timestamp">
          {{activity.content}}
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        orderNo: "",
        activities: [{
          content: '已签收',
          timestamp: '2018-04-12 20:46',
          size: 'large',
          type: 'success',
          icon: 'el-icon-s-claim'
        }, {
          content: '已送达',
          timestamp: '2018-04-03 20:46',
          color: '#0bbd87'
        }, {
          content: '派送中',
          timestamp: '2018-04-03 20:46',
          size: 'large',
          type: 'primary ',
          icon: "el-icon-bicycle"
        }, {
          content: '运输中',
          timestamp: '2018-04-03 20:46',
          size: 'large',
          type: "info",
          icon: "el-icon-truck"
        }, {
          content: '运输中',
          timestamp: '2018-04-03 20:46',
          size: 'large',
          type: "info",
          icon: "el-icon-truck"
        }, {
          content: '运输中',
          timestamp: '2018-04-03 20:46',
          size: 'large',
          type: "info",
          icon: "el-icon-truck"
        }, {
          content: '已取件',
          timestamp: '2018-04-03 20:46',
          size: 'large',
          type: "primary",
          icon: "el-icon-s-cooperation"
        }]
      }
    },
    methods: {

    },
    mounted: function() {

    },
    created() {}
  }
</script>

<style>
  .el-input__inner {
    border-radius: 10px !important;
    height: 3.2em !important;
    font-size: 100% !important;
  }

  .con {
    width: 100%;
    height: 100%;
    background: url("../images/bg.png");
    background-size: 100% auto;
    overflow: hidden;
  }

  .search_box {
    width: 80%;
    margin: 0 auto;
    padding: 4%;
    margin-top: 5%;
    border: 1px solid #6B6B70;
    border-radius: 10px;
    background-color: rgba(251, 251, 251, 60%);
  }

  .el-button {
    width: 100% !important;
    height: 3.2em !important;
    border-radius: 10px !important;
  }

  .kd_head {
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }
  .el-divider__text{
    background-color: rgba(251, 251, 251, 0%) !important;
  }
</style>
