<template>
  <div class="continer">
    <a class="beian" href="https://beian.miit.gov.cn/"> 备案号:湘ICP备2023009443号-1 © 2023 BaiYang All Rights Reserved</a>
    <div class="no_click">

    </div>
    <div class="bf" @click="bf()">
      <p id="process">0%</p>
      <img class="wait_bg" src="../images/wait_bg.webp" />
      <img class="wait_bg_g" src="../images/wait_bg_g.png" />
      <img class="wait_zi" src="../images/wait_zi.png" />
      <img class="wait_r" src="../images/wait_r.webp" />
      <img class="wait_zhou" src="../images/wait_zhou.webp" />
      <img class="wait_kai" src="../images/wait_kai.webp" />
    </div>
    <!-- 关闭声音 -->
    <svg t="1681649577737" @click="bfs = true,bf()" v-if="!bfs" class="icon yyan" viewBox="0 0 1024 1024" version="1.1"
      xmlns="http://www.w3.org/2000/svg" p-id="1968" width="200" height="200">
      <path
        d="M883.498667 140.501333a42.666667 42.666667 0 0 1 0 60.330667l-682.666667 682.666667a42.666667 42.666667 0 0 1-60.330667-60.330667l76.117334-76.117333A170.666667 170.666667 0 0 1 128 597.333333v-170.666666a170.666667 170.666667 0 0 1 170.666667-170.666667h27.221333a42.666667 42.666667 0 0 0 27.306667-9.898667l92.202666-76.8c96.469333-80.426667 236.288-31.530667 271.616 77.354667l106.154667-106.154667a42.666667 42.666667 0 0 1 60.330667 0zM640 323.669333V300.373333c0-72.362667-84.394667-111.872-139.946667-65.536l-92.245333 76.8A128 128 0 0 1 325.930667 341.333333H298.666667a85.333333 85.333333 0 0 0-85.333334 85.333334v170.666666a85.333333 85.333333 0 0 0 58.325334 80.981334v0.042666c3.157333 1.28 6.101333 2.858667 8.832 4.778667L640 323.669333z m-194.56 531.072c111.146667 92.586667 279.893333 13.568 279.893333-131.114666v-201.642667a42.666667 42.666667 0 0 0-85.333333 0v201.642667c0 72.32-84.394667 111.872-139.946667 65.536l-46.122666-38.4-0.512 0.512A42.666667 42.666667 0 1 0 395.093333 812.8l50.261334 41.898667z"
        fill="#C0AF63" p-id="1969"></path>
    </svg>
    <!-- 开启声音 -->
    <svg t="1681649530725" @click="bfs = false,zt()" v-if="bfs" class="icon yyan" viewBox="0 0 1024 1024" version="1.1"
      xmlns="http://www.w3.org/2000/svg" p-id="1754" width="200" height="200">
      <path
        d="M283.221333 341.333333H256a85.333333 85.333333 0 0 0-85.333333 85.333334v170.666666a85.333333 85.333333 0 0 0 58.325333 80.981334v0.042666a42.666667 42.666667 0 0 1-26.965333 80.896l-1.194667-0.384A170.752 170.752 0 0 1 85.333333 597.333333v-170.666666a170.666667 170.666667 0 0 1 170.666667-170.666667h27.221333a42.666667 42.666667 0 0 0 27.306667-9.898667l92.202667-76.8C513.92 76.586667 682.666667 155.605333 682.666667 300.373333v423.253334c0 144.682667-168.746667 223.744-279.893334 131.114666L352.426667 812.8a42.624 42.624 0 0 1 25.557333-76.842667c11.093333 0 21.205333 4.266667 28.8 11.178667l0.042667-0.085333 50.517333 42.069333c55.552 46.336 139.946667 6.826667 139.946667-65.536V300.373333c0-72.362667-84.394667-111.872-139.946667-65.536l-92.245333 76.8A128 128 0 0 1 283.264 341.333333zM841.386667 269.056a42.666667 42.666667 0 0 0-61.44 59.221333C818.773333 368.64 853.333333 436.266667 853.333333 512s-34.56 143.36-73.386666 183.722667a42.666667 42.666667 0 0 0 61.44 59.221333C893.610667 700.672 938.666667 612.48 938.666667 512s-45.056-188.672-97.28-242.944z"
        fill="#C0AF63" p-id="1755"></path>
    </svg>
    <div class="lm_box">
      <audio style="display: none;" controls="controls" class="music" id="behindAudioPlay" src="../images/gq.mp3"
        loop="loop" preload="preload"></audio>
      <!-- 主图-需要变成block -->
      <img style="display: none;" class="zi" src="../images/box_bg_1_zi.png" />
      <img style="display: none;" class="could_1" src="../images/could_1.webp" />
      <img style="display: none;" class="could_2" src="../images/could_2.webp" />
      <img style="display: none;" class="could_3" src="../images/could_3.webp" />
      <img style="display: none;" class="could_4" src="../images/could_4.webp" />
      <img style="display: none;" class="could_5" src="../images/could_5.webp" />
      <img style="display: none;" class="could_6" src="../images/could_6.webp" />
      <img style="display: none;" class="could_7" src="../images/could_7.webp" />
      <img style="display: none;" class="could_8" src="../images/could_8.webp" />

      <!-- 千里江山 -->
      <img style="display: none;" class="qljs_zi" src="../images/box_bg_2_zi.png" />
      <img style="display: none;" class="chuan" src="../images/chuan.webp" />
      <img style="display: none;" class="yu" src="../images/yu.webp" />

      <!-- 仕女 -->
      <img style="display: none;" class="shinv_zi" src="../images/box_bg_4_zi.png" />
      <img style="display: none;" class="shinv" src="../images/shinv.webp" />
      <img style="display: none;" class="shinv_shou" src="../images/shinv_shou.webp" />
      <img style="display: none;" class="shinv_shouzhi" src="../images/shouzhi.png" />
      <!-- 花瓣-1 -->
      <img style="display: none;" class="hua" src="../images/hua.webp" />
      <img style="display: none;" class="dt_hua" src="../images/dt_hua.gif" />

      <!-- 花鸟 -->
      <img style="display: none;" class="huaniao_zi" src="../images/box_bg_5_zi.png" />
      <img style="display: none;" class="fh" src="../images/fh.gif" />
      <img style="display: none;" class="hn_zhi" src="../images/hn_zhi.webp" />
      <img style="display: none;" class="hn_bi" src="../images/hn_bi.webp" />
      <img style="display: none;" class="hn_zb" src="../images/hn_zb.webp" />

      <!-- 铜雕 -->
      <img style="display: none;" class="td_zi" src="../images/box_bg_3_zi.png" />
      <img style="display: none;" class="td_fang" src="../images/td_fang.webp" />
      <img style="display: none;" class="td_yun_1 td_dong" src="../images/td_yun_1.webp" />
      <img style="display: none;z-index: 9;" class="td_yun_1" src="../images/td_yun_1.webp" />
      <img style="display: none;" class="td_yun_2" src="../images/td_yun_2.webp" />
      <img style="display: none;" class="td_yun_3" src="../images/td_yun_3.webp" />
      <img style="display: none;" class="td_langan" src="../images/td_langan.webp" />
      <img style="display: none;" class="td_langan_1" src="../images/td_langan_1.webp" />
      <img style="display: none;" class="td_langan_2" src="../images/td_langan_2.webp" />
      <img style="display: none;" class="td_pz" src="../images/td_pz.webp" />
      <img style="display: none;" class="td_zhi" src="../images/td_zhi.webp" />
      <img style="display: none;" class="td_ya" src="../images/td_ya.webp" />
      <img style="display: none;" class="td_chong_1" src="../images/td_chong_2.gif" />
      <img style="display: none;" class="td_chong_2" src="../images/td_chong_2.gif" />
      <img style="display: none;" class="td_chong_3" src="../images/td_chong_3.gif" />

      <!-- 神佛 -->
      <img style="display: none;" class="box_bg_6_zi" src="../images/box_bg_6_zi.png" />
      <img style="display: none;" class="fo_yun" src="../images/fo_yun.webp" />
      <img style="display: none;" class="fo_gy" src="../images/fo_gy.webp" />
      <img style="display: none;" class="fo_guang" src="../images/fo_guang.webp" />
      <img style="display: none;" class="fo_bg" src="../images/fo_bg.webp" />
      <img style="display: none;" class="fo_niao" src="../images/fo_niao.webp" />
      <img style="display: none;" class="he_1" src="../images/he_1.gif" />
      <img style="display: none;" class="he_2" src="../images/he_2.gif" />
      <!-- 分类背景图 -->
      <div class="lm_img_box">
        <img style="display: block;box-shadow: 0 0 50px 20px rgb(153 125 82 / 60%);backdrop-filter: blur(5px);"
          src="../images/box_bg_1.webp" />
        <img style="display: none;box-shadow: 0 0 50px 20px rgb(94 101 64 / 60%);" src="../images/box_bg_2.png" />
        <img style="display: none;box-shadow: 0 0 50px 20px rgb(153 125 82 / 60%);" src="../images/box_bg_3.webp" />
        <img style="display: none;box-shadow: 0 0 50px 20px rgb(120 25 0 / 35%);" src="../images/box_bg_4.webp" />
        <img style="display: none;box-shadow: 0 0 50px 20px rgb(188 153 83 / 60%);" src="../images/box_bg_5.webp" />
        <img style="display: none;box-shadow: 0 0 50px 20px rgb(251 229 78 / 40%);" src="../images/box_bg_6.webp" />
        <!-- 分类小图 -->
        <div class="xlm_img_box">
          <img class="db1" @mouseover="mouseover('1')" @mouseout="mouseout('1')" @click="toDo('1')"
            :src="require('../images/'+tDSrc+'.webp')" />
          <img class="db2" @mouseover="mouseover('2')" @mouseout="mouseout('2')" @click="toDo('2')"
            :src="require('../images/'+jsSrc+'.webp')" />
          <img class="db3" @mouseover="mouseover('3')" @mouseout="mouseout('3')" @click="toDo('3')"
            :src="require('../images/'+gfSrc+'.webp')" />
          <img class="db4" @mouseover="mouseover('4')" @mouseout="mouseout('4')" @click="toDo('4')"
            :src="require('../images/'+lkSrc+'.webp')" />
          <img class="db5" @mouseover="mouseover('5')" @mouseout="mouseout('5')" @click="toDo('5')"
            :src="require('../images/'+gySrc+'.webp')" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  function none() {
    $(".lm_img_box>img:eq(0)").css("display", "block");
    $(".zi").css("display", "block");
    $(".could_1").css("display", "block");
    $(".could_2").css("display", "block");
    $(".could_3").css("display", "block");
    $(".could_4").css("display", "block");
    $(".could_5").css("display", "block");
    $(".could_6").css("display", "block");
    $(".could_7").css("display", "block");
    $(".could_8").css("display", "block");
    $(".lm_img_box>img:eq(1)").css("display", "none");
    $(".lm_img_box>img:eq(2)").css("display", "none");
    $(".lm_img_box>img:eq(3)").css("display", "none");
    $(".lm_img_box>img:eq(4)").css("display", "none");
    $(".lm_img_box>img:eq(5)").css("display", "none");
  }

  function sy() {
    $(".lm_img_box>img:eq(0)").css("display", "none");
    $(".zi").css("display", "none");
    $(".could_1").css("display", "none");
    $(".could_2").css("display", "none");
    $(".could_3").css("display", "none");
    $(".could_4").css("display", "none");
    $(".could_5").css("display", "none");
    $(".could_6").css("display", "none");
    $(".could_7").css("display", "none");
    $(".could_8").css("display", "none");
  }

  export default {
    // 预加载首页loading背景图
    beforeCreate() {
      let count = 0;
      let process = 1;
      let wait_imgs = [
        require('../images/wait_bg.webp'),
        require('../images/wait_bg_g.png'),
        require('../images/wait_zi.png'),
        require('../images/wait_r.webp'),
        require('../images/wait_zhou.webp'),
        require('../images/wait_kai.webp'),
      ]

      let index_imgs = [
        require('../images/bg.png'),
        require('../images/box_bg_1.webp'),
        require('../images/box_bg_1_zi.png'),
        require('../images/box_bg_2.png'),
        require('../images/box_bg_2_zi.png'),
        require('../images/box_bg_3.webp'),
        require('../images/box_bg_3_zi.png'),
        require('../images/box_bg_4.webp'),
        require('../images/box_bg_4_zi.png'),
        require('../images/box_bg_5.webp'),
        require('../images/box_bg_5_zi.png'),
        require('../images/box_bg_6.webp'),
        require('../images/box_bg_6_zi.png'),
        require('../images/box_lm_1.webp'),
        require('../images/box_lm_1_x.webp'),
        require('../images/box_lm_2.webp'),
        require('../images/box_lm_2_x.webp'),
        require('../images/box_lm_3.webp'),
        require('../images/box_lm_3_x.webp'),
        require('../images/box_lm_4.webp'),
        require('../images/box_lm_4_x.webp'),
        require('../images/box_lm_5.webp'),
        require('../images/box_lm_5_x.webp'),
        require('../images/chuan.webp'),
        require('../images/btn.webp'),
        require('../images/could_1.webp'),
        require('../images/could_2.webp'),
        require('../images/could_3.webp'),
        require('../images/could_4.webp'),
        require('../images/could_5.webp'),
        require('../images/could_6.webp'),
        require('../images/could_7.webp'),
        require('../images/could_8.webp'),
        require('../images/dt_hua.gif'),
        require('../images/fh.gif'),
        require('../images/fo_bg.webp'),
        require('../images/fo_guang.webp'),
        require('../images/fo_gy.webp'),
        require('../images/fo_niao.webp'),
        require('../images/fo_yun.webp'),
        require('../images/he_1.gif'),
        require('../images/he_2.gif'),
        require('../images/hn_bi.webp'),
        require('../images/hn_zb.webp'),
        require('../images/hn_zhi.webp'),
        require('../images/hua.webp'),
        require('../images/shinv.webp'),
        require('../images/shinv_shou.webp'),
        require('../images/shouzhi.png'),
        require('../images/td_chong_2.gif'),
        require('../images/td_chong_3.gif'),
        require('../images/td_fang.webp'),
        require('../images/td_langan.webp'),
        require('../images/td_langan_1.webp'),
        require('../images/td_langan_2.webp'),
        require('../images/td_pz.webp'),
        require('../images/td_ya.webp'),
        require('../images/td_yun_1.webp'),
        require('../images/td_yun_2.webp'),
        require('../images/td_yun_3.webp'),
        require('../images/td_zhi.webp'),
        require('../images/yu.webp'),

        // // 仕女页面
        // require('../images/shinv/bg.webp'),
        // require('../images/shinv/gf.webp'),
        // require('../images/shinv/gf_2.webp'),
        // require('../images/shinv/gf_2_zi.png'),
        // require('../images/shinv/gf_chuan.webp'),
        // require('../images/shinv/gf_old.png'),
        // require('../images/shinv/gf_shinv_zi.png'),
        // require('../images/shinv/gf_pr.png'),
        // require('../images/shinv/gf_tmh.png'),
        // require('../images/shinv/gf_xfr.webp'),
        // require('../images/shinv/gf_yun_2.png'),
        // require('../images/shinv/gf_yun_3.png'),
        // require('../images/shinv/gf_yun_4.webp'),
        // require('../images/shinv/gf_zi.webp'),
        // require('../images/shinv/gf_zi_3.png'),
        // require('../images/shinv/hua.webp'),
        // require('../images/shinv/hui.png'),
        // require('../images/shinv/yun.png'),
        // require('../images/shinv/shan_left.png'),
        // require('../images/shinv/shan_rigt.png'),
        // require('../images/shinv/shinv_zi.png'),
        // require('../images/shinv/xfr_old.webp'),
        // // // 铜雕页面
        // require('../images/td/bg.webp'),
        // require('../images/td/hua_1.png'),
        // require('../images/td/hua_2.png'),
        // require('../images/td/hua_3.webp'),
        // require('../images/td/td_bg_1.webp'),
        // require('../images/td/td_d.webp'),
        // require('../images/td/td_p.webp'),
        // require('../images/td/td_s.webp'),
        // require('../images/td/td_tu.gif'),
        // require('../images/td/td_zi_1.png'),
        // require('../images/td/td_zi_2.png'),
        // require('../images/td/td_zi_3.png'),

        // // 山水页面
        // require('../images/ss/js_1.png'),
        // require('../images/ss/js_2.png'),
        // require('../images/ss/js_3.png'),
        // require('../images/ss/js_4.png'),
        // require('../images/ss/js_7.webp'),
        // require('../images/ss/js_8.png'),
        // require('../images/ss/js_ss.png'),
        // require('../images/ss/ss_bg_1.webp'),
        // require('../images/ss/ss_bg_js.webp'),
        // require('../images/ss/ss_bg_z.png'),
        // require('../images/ss/ss_dz.webp'),
        // require('../images/ss/ss_he.webp'),
        // require('../images/ss/ss_r.webp'),
        // require('../images/ss/ss_shan.webp'),
        // require('../images/ss/ss_r_js.png'),
        // require('../images/ss/ss_shan_2.webp'),

        // //神佛页面
        // require('../images/fo/fo.png'),
        // require('../images/fo/fo_1.webp'),
        // require('../images/fo/fo_3.webp'),
        // require('../images/fo/fo_t.png'),
        // require('../images/fo/fo_zi.png'),
        // require('../images/fo/left_1.webp'),
        // require('../images/fo/left_2.webp'),
        // require('../images/fo/right_1.webp'),
        // require('../images/fo/right_2.webp'),

        // // 花鸟页面
        // require('../images/hn/hn_bg.webp'),
        // require('../images/hn/hn_js.webp'),
        // require('../images/hn/hn_kq.webp'),
        // require('../images/hn/hn_y.webp'),
        // require('../images/hn/hn_zi.png'),
        // require('../images/hn/zhi_left.webp'),
        // require('../images/hn/zhi_right.webp'),
        // require('../images/hn/zhi_right_1.webp'),

        require('../images/gq.mp3'),
      ]
      let size = index_imgs.length;
      for (let img of wait_imgs) {
        let image = new Image();
        image.src = img;
        image.onload = (e) => {
          count++;
          if (count == wait_imgs.length) {
            $(".bf").fadeIn()
            for (let index_img of index_imgs) {
              let index_image = new Image();
              index_image.src = index_img;
              index_image.onload = (e) => {
                process = process + 1;
                $("#process").html(Math.min(((process / size) * 100).toFixed(0), 100) + "%")
                console.log("p:"+process+"s:"+size)
                if (process == size) {
                  $("#process").css("display", "none")
                  $(".no_click").css("display", "none")
                  $(".wait_kai").css("display", "block")
                }
              };
              index_image.onerror = (err) => {
                console.error(err)
                size = size - 1;
                process = process - 1;
              }
            }
          }
        };
        image.onerror = (err) => {
          console.error(err)
          size = size - 1;
          count = count -1;
        }
      }
    },
    data() {
      return {
        ishow: true,
        bfs: true,
        isUp: false,
        isBf: false,
        tDSrc: "box_lm_4",
        jsSrc: "box_lm_5",
        gfSrc: "box_lm_3",
        lkSrc: "box_lm_2",
        gySrc: "box_lm_1"
      }
    },
    methods: {
      // 播放音乐
      bf() {
        $(".beian").css("display","none");
        $(".bf").fadeOut("slow");
        $(".lm_img_box>img:eq(0)").css("display", "block");
        $(".zi").css("display", "block");
        $(".could_1").css("display", "block");
        $(".could_2").css("display", "block");
        $(".could_3").css("display", "block");
        $(".could_4").css("display", "block");
        $(".could_5").css("display", "block");
        $(".could_6").css("display", "block");
        $(".could_7").css("display", "block");
        $(".could_8").css("display", "block");
        $(".could_8").css("display", "block");
        $(".could_8").css("display", "block");
        $(".lm_img_box").css("display", "block");
        $(".xlm_img_box").css("display", "block");
        var music = document.getElementsByClassName("music");
        music[0].play();
        this.isBf = false
      },
      // 暂停音乐
      zt() {
        var music = document.getElementsByClassName("music");
        music[0].pause();
      },
      // 页面跳转
      toDo(types) {
        if (types == "1") {
          alert("敬请期待!")
          // this.$router.push({
          //   path: "/td"
          // });
        } else if (types == "2") {
          alert("敬请期待!")
          // this.$router.push({
          //   path: "/ss"
          // });
        } else if (types == "3") {
          alert("敬请期待!")
          // this.$router.push({
          //   path: "/gf"
          // });
        } else if (types == "4") {
          alert("敬请期待!")
          // this.$router.push({
          //   path: "/hn"
          // });
        } else if (types == "5") {
          alert("敬请期待!")
          // this.$router.push({
          //   path: "/fo"
          // });
        }
      },
      // 移入
      mouseover(types) {
        if (types == "1") {
          this.tDSrc = "box_lm_4_x";
          $(".xlm_img_box>img:eq(0)").css('transform', 'scale(2, 2)')
        } else if (types == "2") {
          this.jsSrc = "box_lm_5_x";
          $(".xlm_img_box>img:eq(1)").css('transform', 'scale(2, 2)')
        } else if (types == "3") {
          this.gfSrc = "box_lm_3_x";
          $(".xlm_img_box>img:eq(2)").css('transform', 'scale(2, 2)')
        } else if (types == "4") {
          this.lkSrc = "box_lm_2_x";
          $(".xlm_img_box>img:eq(3)").css('transform', 'scale(2, 2)')
        } else if (types == "5") {
          this.gySrc = "box_lm_1_x";
          $(".xlm_img_box>img:eq(4)").css('transform', 'scale(2, 2)')
        }
      },
      // 移出
      mouseout(types) {
        if (types == "1") {
          this.tDSrc = "box_lm_4";
          $(".xlm_img_box>img:eq(0)").css('transform', 'scale(1.5, 1.5)')
        } else if (types == "2") {
          this.jsSrc = "box_lm_5";
          $(".xlm_img_box>img:eq(1)").css('transform', 'scale(1.5, 1.5)')
        } else if (types == "3") {
          this.gfSrc = "box_lm_3";
          $(".xlm_img_box>img:eq(2)").css('transform', 'scale(1.5, 1.5)')
        } else if (types == "4") {
          this.lkSrc = "box_lm_2";
          $(".xlm_img_box>img:eq(3)").css('transform', 'scale(1.5, 1.5)')
        } else if (types == "5") {
          this.gySrc = "box_lm_1";
          $(".xlm_img_box>img:eq(4)").css('transform', 'scale(1.5, 1.5)')
        }
      }
    },
    mounted: function() {
      // 铜雕
      $(".xlm_img_box>img:eq(0)").hover(function() {
        sy()
        $(".lm_img_box>img:eq(2)").css("display", "block");
        $(".td_zi").css("display", "block");
        $(".td_fang").css("display", "block");
        $(".td_yun_1").css("display", "block");
        $(".td_yun_2").css("display", "block");
        $(".td_yun_3").css("display", "block");
        $(".td_langan").css("display", "block");
        $(".td_langan_1").css("display", "block");
        $(".td_langan_2").css("display", "block");
        $(".td_pz").css("display", "block");
        $(".td_zhi").css("display", "block");
        $(".td_ya").css("display", "block");
        $(".td_chong_1").css("display", "block");
        $(".td_chong_2").css("display", "block");
        $(".td_chong_3").css("display", "block");
        $(".continer").addClass("tdbg")
      }, function() {
        none();
        $(".td_zi").css("display", "none");
        $(".td_fang").css("display", "none");
        $(".td_yun_1").css("display", "none");
        $(".td_yun_2").css("display", "none");
        $(".td_yun_3").css("display", "none");
        $(".td_langan").css("display", "none");
        $(".td_langan_1").css("display", "none");
        $(".td_langan_2").css("display", "none");
        $(".td_pz").css("display", "none");
        $(".td_zhi").css("display", "none");
        $(".td_ya").css("display", "none");
        $(".td_chong_1").css("display", "none");
        $(".td_chong_2").css("display", "none");
        $(".td_chong_3").css("display", "none");
        $(".continer").removeClass("tdbg")
      })
      // 千里江山
      $(".xlm_img_box>img:eq(1)").hover(function() {
        sy()
        $(".lm_img_box>img:eq(1)").css("display", "block");
        // $(".shan").css("display", "block");
        $(".chuan").css("display", "block");
        // $(".shui").css("display", "block");
        $(".yu").css("display", "block");
        $(".qljs_zi").css("display", "block");
        $(".continer").addClass("qljsbg")
      }, function() {
        none()
        // $(".shan").css("display", "none");
        $(".chuan").css("display", "none");
        // $(".shui").css("display", "none");
        $(".yu").css("display", "none");
        $(".qljs_zi").css("display", "none");
        $(".continer").removeClass("qljsbg")
      })
      // 贵妃上马
      $(".xlm_img_box>img:eq(2)").hover(function() {
        sy()
        $(".lm_img_box>img:eq(3)").css("display", "block");
        $(".hua").css("display", "block");
        $(".dt_hua").css("display", "block");
        $(".shinv").css("display", "block");
        $(".shinv_shou").css("display", "block");
        $(".shinv_shouzhi").css("display", "block");
        $(".shinv_zi").css("display", "block");
        $(".fg").css("display", "block");
        $(".continer").addClass("gfbg")
      }, function() {
        none()
        $(".hua").css("display", "none");
        $(".dt_hua").css("display", "none");
        $(".shinv").css("display", "none");
        $(".shinv_shou").css("display", "none");
        $(".shinv_shouzhi").css("display", "none");
        $(".shinv_zi").css("display", "none");
        $(".fg").css("display", "none");
        $(".continer").removeClass("gfbg")
      })
      // 榴开见子
      $(".xlm_img_box>img:eq(3)").hover(function() {
        sy()
        $(".lm_img_box>img:eq(4)").css("display", "block");
        $(".huaniao_zi").css("display", "block");
        $(".fh").css("display", "block");
        $(".hn_zhi").css("display", "block");
        $(".hn_bi").css("display", "block");
        $(".hn_zb").css("display", "block");
        $(".continer").addClass("lkjztu")
      }, function() {
        none()
        $(".huaniao_zi").css("display", "none");
        $(".fh").css("display", "none");
        $(".hn_zhi").css("display", "none");
        $(".hn_bi").css("display", "none");
        $(".hn_zb").css("display", "none");
        $(".continer").removeClass("lkjztu")
      })
      // 文殊菩萨
      $(".xlm_img_box>img:eq(4)").hover(function() {
        sy()
        $(".lm_img_box>img:eq(5)").css("display", "block");
        $(".box_bg_6_zi").css("display", "block");
        $(".fo_yun").css("display", "block");
        $(".fo_gy").css("display", "block");
        $(".fo_guang").css("display", "block");
        $(".fo_bg").css("display", "block");
        $(".fo_niao").css("display", "block");
        $(".he_1").css("display", "block");
        $(".he_2").css("display", "block");
        $(".continer").addClass("fobg");
      }, function() {
        none()
        $(".box_bg_6_zi").css("display", "none");
        $(".fo_yun").css("display", "none");
        $(".fo_gy").css("display", "none");
        $(".fo_guang").css("display", "none");
        $(".fo_bg").css("display", "none");
        $(".fo_niao").css("display", "none");
        $(".continer").removeClass("fobg")
        $(".he_1").css("display", "none");
        $(".he_2").css("display", "none");
      })
      // 船动态效果
      TweenMax.to(".chuan", 2, {
        rotationZ: 2,
        repeat: -1,
        x: 100,
        yoyo: true,
        // ease: Bounce.easeOut
      });
      // 鱼动态效果
      TweenMax.to(".yu", 2, {
        rotationZ: 1,
        x: 100,
        y: 20,
        repeat: -1,
        rotationX: 30,
        yoyo: true,
        // ease: Bounce.easeOut
      });
      // // 仕女手
      // this.$twenmax.to(".shinv_shou", 2, {
      //   rotationZ: 1,
      //   repeat: -1,
      //   rotationZ: 5,
      //   yoyo: true,
      //   // ease: Bounce.easeOut
      // });
      // // 仕女手指
      TweenMax.to(".shinv_shouzhi", 2, {
        repeat: -1,
        rotationZ: 8,
        x: 8,
        y: 0,
        yoyo: true,
        // ease: Bounce.easeOut
      });
      // 仕女手上的花
      TweenMax.to(".hua", 2, {
        repeat: -1,
        opacity: 0.6,
        yoyo: true,
      });

      // 云1动态效果
      TweenMax.to(".could_1", 4, {
        x: 20,
        y: 0,
        // ease: Bounce.easeOut
        repeat: -1,
        yoyo: true,
      });
      // 云2动态效果
      TweenMax.to(".could_2", 4, {
        delay: 0.5,
        x: -30,
        y: 0,
        // ease: Bounce.easeOut
        repeat: -1,
        yoyo: true,
      });
      // 云3动态效果
      TweenMax.to(".could_3", 4, {
        delay: 0.9,
        x: -20,
        y: -2,
        // ease: Bounce.easeOut
        repeat: -1,
        yoyo: true,
      });
      // 云4动态效果
      TweenMax.to(".could_4", 3, {
        delay: 1,
        x: -20,
        y: -5,
        // ease: Bounce.easeOut
        repeat: -1,
        yoyo: true,
      });
      // 云5动态效果
      TweenMax.to(".could_5", 4, {
        delay: 0.2,
        x: 20,
        y: -5,
        // ease: Bounce.easeOut
        repeat: -1,
        yoyo: true,
      });
      // 云6动态效果
      TweenMax.to(".could_6", 4, {
        x: 20,
        y: -5,
        // ease: Bounce.easeOut
        repeat: -1,
        yoyo: true,
      });
      // 云8动态效果
      TweenMax.to(".could_8", 4, {
        delay: 0.5,
        x: -20,
        y: 5,
        // ease: Bounce.easeOut
        repeat: -1,
        yoyo: true,
      });
      // 铜雕枝子
      TweenMax.to(".td_zhi", 2, {
        rotation: -3,
        y: 5,
        x: -10,
        repeat: -1,
        yoyo: true,
      });
      // 铜雕芽
      TweenMax.to(".td_ya,.td_chong_3", 2, {
        rotation: -5,
        y: 8,
        x: -1,
        repeat: -1,
        yoyo: true,
      });
      // 铜雕栏杆1
      TweenMax.to(".td_langan", 1, {
        delay: 1,
        opacity: 1,
        repeat: -1,
        yoyo: true,
      });
      // 铜雕栏杆2
      TweenMax.to(".td_langan_1", 2, {
        delay: 3,
        opacity: 1,
        repeat: -1,
        yoyo: true,
      });
      // 铜雕栏杆3
      TweenMax.to(".td_langan_2", 2, {
        delay: 2,
        opacity: 1,
        repeat: -1,
        yoyo: true,
      });
      // 铜雕云1
      TweenMax.to(".td_dong", 2, {
        x: 10,
        repeat: -1,
        yoyo: true,
      });
      // 鸟嘴
      TweenMax.to(".hn_zb", 2, {
        rotation: 10,
        repeat: -1,
        y: 5,
        x: -4,
        yoyo: true,
      });
      // 花鸟 枝子
      TweenMax.to(".hn_zhi,.hn_bi", 2, {
        rotation: -1,
        y: 2,
        x: -8,
        repeat: -1,
        yoyo: true,
      });
      // 佛光
      TweenMax.to(".fo_guang", 1.5, {
        repeat: -1,
        yoyo: true,
        opacity: 1,
        y: -10,
        x: -5
      });
    },
    created() {

      const that = this;
      that.timer = setInterval(() => {
        if (document.readyState === 'complete') {
          this.isBf = true;
          window.clearInterval(that.timer);
        }
      }, 1000);
    }
  }
</script>
<style scoped>
  .lm_box {
    width: 76.825%;
    height: auto;
    position: relative;
    top: 2%;
  }

  .lm_img_box {
    width: 100%;
    height: 100%;
    padding: 0;
    position: relative;
    display: none;
  }

  .lm_img_box>img {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .xlm_img_box {
    position: absolute;
    top: 75%;
    display: none;
  }

  .xlm_img_box>img {
    cursor: pointer;
    position: relative;
    width: 13%;
    height: 100%;
    transform: scale(1.5, 1.5);
    z-index: 20;
  }

  .db1 {
    left: -5.3%;
  }

  .db2 {
    left: 2.8%;
    padding-right: 2%;
  }

  .db3 {
    left: 13.5%;
    padding-right: 3%;
  }

  .db4 {
    left: 22%;
    padding-right: 1%;
  }

  .db5 {
    left: 32%;
    margin-top: 2%;
  }

  .qljs_zi {
    position: absolute;
    z-index: 3;
    width: 24%;
    bottom: 41%;
    left: 14%;
  }

  .chuan {
    position: absolute;
    z-index: 3;
    width: 25%;
    bottom: 60%;
    left: -6.1%;
    transform: rotate(-5deg);
  }

  .yu {
    position: absolute;
    z-index: 2;
    width: 3%;
    left: -5%;
    top: 15%;
    transform: rotate(35deg);
  }

  .zi {
    position: absolute;
    z-index: 20;
    width: 61%;
    top: 17%;
    left: 6.8%;
  }

  .could_1 {
    position: absolute;
    z-index: 4;
    width: 64%;
    top: 34.6%;
    left: 0;
  }

  .could_2 {
    position: absolute;
    z-index: 3;
    width: 87%;
    top: 49%;
    right: 0;
  }

  .could_3 {
    position: absolute;
    z-index: 3;
    width: 21%;
    left: 55.5%;
    top: 35.4%;
  }

  .could_4 {
    position: absolute;
    z-index: 3;
    width: 80%;
    right: 0;
    top: 37%;
  }

  .could_5 {
    position: absolute;
    z-index: 3;
    width: 49%;
    left: 18%;
    top: 41%;
  }

  .could_6 {
    position: absolute;
    z-index: 3;
    width: 17%;
    top: 28%;
    left: 14%;
  }

  .could_7 {
    position: absolute;
    z-index: 2;
    width: 40%;
    top: 32%;
    left: 15.6%;
  }

  .could_8 {
    position: absolute;
    z-index: 3;
    width: 23%;
    top: 41.9%;
    right: 19%;
  }

  .shinv {
    position: absolute;
    z-index: 10;
    width: 40%;
    top: -39%;
    left: -7%;
  }

  .shinv_shou {
    position: absolute;
    z-index: 10;
    width: 13%;
    top: 17%;
    left: 15.4%;
  }

  .shinv_shouzhi {
    position: absolute;
    z-index: 10;
    width: 6%;
    top: 27.8%;
    left: 22.4%;
  }

  .hua {
    position: absolute;
    z-index: 10;
    width: 11.6%;
    top: 11%;
    left: 15%;
    opacity: 0;
  }

  .fg {
    opacity: 0;
  }

  .shinv_zi {
    position: absolute;
    z-index: 11;
    width: 25%;
    top: 15%;
    left: 45%;
  }

  .huaniao_zi {
    position: absolute;
    z-index: 11;
    width: 23%;
    top: 13%;
    left: 17%;
  }

  .fh {
    position: absolute;
    z-index: 11;
    width: 70%;
    top: -45%;
    left: 22%;
  }

  .td_zi {
    position: absolute;
    z-index: 11;
    width: 21%;
    top: 1%;
    left: 40%;
  }

  .td_ya {
    position: absolute;
    z-index: 12;
    width: 15%;
    top: 52.2%;
    left: 74.3%;
  }

  .td_fang {
    position: absolute;
    z-index: 11;
    width: 44%;
    top: 3.3%;
    left: -0.1%;
  }

  .td_yun_1 {
    position: absolute;
    z-index: 10;
    width: 44%;
    top: -5.7%;
    left: -0.2%;
  }

  .td_yun_2 {
    position: absolute;
    z-index: 10;
    width: 52%;
    top: -1%;
    left: 47.8%;
  }

  .td_yun_3 {
    position: absolute;
    z-index: 9;
    width: 35%;
    top: 34%;
    left: 56%;
  }

  .td_langan {
    position: absolute;
    z-index: 9;
    width: 48%;
    top: 54.7%;
    left: 40.4%;
    opacity: 0;
  }

  .td_langan_1 {
    position: absolute;
    z-index: 9;
    width: 48%;
    top: 54.7%;
    left: 40.4%;
    opacity: 0;
  }

  .td_langan_2 {
    position: absolute;
    z-index: 9;
    width: 48%;
    top: 45.8%;
    left: 40.4%;
    opacity: 0;
  }

  .td_pz {
    position: absolute;
    z-index: 11;
    width: 27%;
    top: 22.8%;
    left: 77.4%;
  }

  .td_zhi {
    position: absolute;
    z-index: 10;
    width: 48%;
    top: -25%;
    left: 54%;
  }

  .td_chong_1 {
    position: absolute;
    z-index: 11;
    width: 35%;
    top: -12%;
    left: 48%;
  }

  .td_chong_2 {
    position: absolute;
    z-index: 11;
    width: 25%;
    top: 12%;
    left: 22.8%;
    transform: rotateZ(100deg)
  }

  .td_chong_3 {
    position: absolute;
    z-index: 15;
    width: 54%;
    top: -1.2%;
    left: 51.4%;
  }

  .hn_zhi {
    position: absolute;
    z-index: 10;
    width: 58%;
    top: -41%;
    left: 60%;
  }

  .hn_bi {
    position: absolute;
    z-index: 12;
    width: 20%;
    top: 3%;
    left: 78.2%;
  }

  .hn_zb {
    position: absolute;
    z-index: 12;
    width: 3%;
    top: 3.5%;
    left: 87.4%;
  }

  .box_bg_6_zi {
    position: absolute;
    z-index: 8;
    width: 22%;
    top: 9%;
    left: 15%;
  }

  .fo_yun {
    position: absolute;
    z-index: 7;
    width: 100%;
    top: -0.2%;
    left: 0%;
  }

  .fo_gy {
    position: absolute;
    z-index: 10;
    width: 38%;
    top: -8%;
    left: 75%;
  }

  .fo_guang {
    position: absolute;
    z-index: 9;
    width: 22%;
    top: -30%;
    left: 80.5%;
    opacity: 0;
  }

  .fo_bg {
    position: absolute;
    z-index: 8;
    width: 38%;
    top: -43.5%;
    left: 75%;
  }

  .fo_niao {
    position: absolute;
    z-index: 10;
    width: 10%;
    top: 25%;
    left: 46%;
  }

  .dt_hua {
    position: absolute;
    z-index: 10;
    width: 140%;
    top: -48%;
    left: -18%;
  }

  .he_1 {
    position: absolute;
    z-index: 10;
    width: 16%;
    top: 29%;
    left: 45%;
  }

  .he_2 {
    position: absolute;
    z-index: 10;
    width: 30%;
    top: 15%;
    left: 30%;
  }

  .beian {
    z-index: 999;
    font-size: 1.2em;
    color: #BFA880;
    text-decoration: none;
    position: absolute;
    bottom: 1%;
  }
</style>
