import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import Cookies from 'js-cookie'
import index from '../components/index.vue'
import gf from '../components/gf.vue'
import query from '../components/query.vue'


//添加以下代码
Vue.prototype.$isAdmin = false
Vue.prototype.$isCommon = false
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  routes: [{
    path: '/',
    redirect: '/index',
    component:index
  }, {
    path: '/index',
    component: index
  }, {
    path: '/gf',
    component: gf
  }, {
    path: '/query',
    component: query
  }, {
    path: '/product',
    component: () => import("../components/product.vue")
  }, {
    path: '/td',
    component: () => import("../components/td.vue")
  }, {
    path: '/ss',
    component: () => import("../components/ss.vue")
  }, {
    path: '/fo',
    component: () => import("../components/fo.vue")
  }, {
    path: '/hn',
    component: () => import("../components/hn.vue")
  }]
})

export default router
